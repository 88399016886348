import {
  SETUP_ACTIVITY_STATE,
  SETUP_AVERAGE_GIFT_STATE,
  SETUP_DONATIONS_STAT_STATE,
  SET_ACTIVITIES,
  SET_AVERAGE_GIFT,
  SET_BOOKING_TOTALS,
  SET_DONATIONS_STAT,
  SET_ENQUIRY_TOTALS,
  SET_TOTALS,
} from "@store/mutation-types";

import { conversionRate } from "@utils/calcs";
import { http } from "@plugins/axios";
import { supabase } from "@plugins/supabase";

const getDefaultState = () => {
  return {
    activities: null,
    donations: null,
    averageGift: null,
    enquiryTotals: {},
    bookingTotals: {},
    firmTotals: {},
  };
};

// initial state
const state = getDefaultState();

const getters = {
  latestActivities: (state) => state.activities,
  latestDonations: (state) => state.donations,
  averageGift: (state) => state.averageGift,
  enquiryTotals: (state) => state.enquiryTotals,
  bookingTotals: (state) => state.bookingTotals,
  firmTotals: (state) => state.firmTotals,
};

const actions = {
  resetActivityState({ commit }) {
    commit("SETUP_ACTIVITY_STATE");
  },
  async fetchLatest({ commit }) {
    await http
      .get("latest/")
      .then(function (response) {
        commit("SET_ACTIVITIES", response.data);
      })
      .catch(function (error) {
        console.log(error.reponse.data);
      });
  },
  async fetchWSActivities({ commit, rootState, state }) {
    const firm = rootState.setup.firm[0]?.firmId;
    const { token } = rootState.auth;
    const socket = new WebSocket(
      `${process.env.VUE_APP_WS_URL}activities/${firm}/?token=${token}`
    );
    socket.onmessage = function (event) {
      let latest_activities = [];
      if (state.activities) {
        latest_activities = state.activities;
      }
      let data = JSON.parse(event.data);
      if (data.message.firm == firm) {
        latest_activities.unshift(data.message);
      }
      commit("SET_ACTIVITIES", latest_activities);
    };
  },

  fetchDonations({ rootState, commit }) {
    const { firmDonationCount } = rootState.setup.firm[0];
    commit("SET_DONATIONS", firmDonationCount);
  },

  fetchAverageGift({ rootState, commit }) {
    const { firmAverageGift } = rootState.setup.firm[0];
    commit("SET_AVERAGE_GIFT", Math.ceil(firmAverageGift));
  },

  async fetchEnquiryTotals({ commit }, firm) {
    const { data } = await supabase
      .from("enquiry_status")
      .select(`*`)
      .eq(`enquiryFirm_id`, firm.firmId)
      .order("enquiryStatus", { ascending: true });
    const status = ["New", "Responded", "Confirmed", "Draft sent", "Donated"];
    let enqtotals = status.map((item) => {
      let x = data.filter(
        (i) => i.enquiryStatus === item && i.enquiryDonated === false
      );
      if (x.length) {
        return { [item]: x[0].total };
      } else {
        return { [item]: 0 };
      }
    });
    let enqs = {};
    for (let i = 0; i < enqtotals.length; i++) {
      Object.assign(enqs, enqtotals[i]);
    }
    const amount = (item) => item.total;
    const sum = (prev, next) => prev + next;
    let totalEnquiries = 0;
    if (data.length > 0) {
      totalEnquiries = data.map(amount).reduce(sum);
    }
    const donations = data.filter((item) => item.enquiryDonated === true);
    if (donations.length > 0) {
      enqs.Donated = donations.map(amount).reduce(sum);
    }
    const totals = {
      totalEnquiries: totalEnquiries,
      enquiries: [
        enqs.New,
        enqs.Responded,
        enqs.Confirmed,
        enqs["Draft sent"],
        enqs.Donated,
      ],
      conversion: conversionRate(
        totalEnquiries,
        enqs.Responded + enqs.Confirmed + enqs.Donated + enqs["Draft sent"]
      ),
    };
    commit("SET_ENQUIRY_TOTALS", totals);
  },

  async fetchBookingTotals({ commit }, firm) {
    const { data } = await supabase
      .from("booking_status")
      .select(`*`)
      .eq(`bookingFirm_id`, firm.firmId)
      .order("bookingStatus", { ascending: true });

    const status = ["Available", "Booked", "Cancelled", "Donated"];
    let bookingTotals = status.map((item) => {
      let x = data.filter((i) => i.bookingStatus === item);
      if (x.length) {
        return { [item]: x[0].total };
      } else {
        return { [item]: 0 };
      }
    });
    let bks = {};
    for (let i = 0; i < bookingTotals.length; i++) {
      Object.assign(bks, bookingTotals[i]);
    }
    const amount = (item) => item.total;
    const sum = (prev, next) => prev + next;
    let totalBookings = 0;
    if (data.length > 0) {
      totalBookings = data.map(amount).reduce(sum);
    }
    const totals = {
      totalBookings: totalBookings,
      bookings: [bks.Available, bks.Booked, bks.Cancelled, bks.Donated],
      conversion: conversionRate(totalBookings, bks.Booked + bks.Donated),
    };
    commit("SET_BOOKING_TOTALS", totals);
  },

  async fetchTotals({ commit }, firm) {
    const { data: donationData, error: donationError } = await supabase
      .from("api_donation")
      .select("donationValue")
      .eq("donationFirm_id", firm.firmId)
      .eq("donationStatus", "Success");

    if (donationError) {
      console.error("Error fetching donation data:", donationError);
      return;
    }

    const donationTotal = donationData.reduce(
      (sum, donation) => sum + donation.donationValue,
      0
    );
    const numberOfDonations = donationData.length;

    if (donationError) {
      console.error("Error fetching donation total:", donationError);
      return;
    }

    const totals = {};
    totals.latestTotal = Math.ceil(donationTotal);
    totals.latestDonationCount = Math.ceil(numberOfDonations);
    totals.grandTotal = Math.ceil(firm.firmGrandTotal);
    totals.firmTarget = Math.ceil(firm.firmTarget);
    totals.lastCampaign = Math.ceil(firm.firmPreviousCampaign);
    totals.pledges = Math.ceil(firm.firmPledgeCount || 0);
    totals.partnerPledges = Math.ceil(firm.firmPartnerPledgeCount || 0);
    console.log(totals);
    commit("SET_TOTALS", totals);
  },
};

const mutations = {
  [SETUP_ACTIVITY_STATE]: (state) => Object.assign(state, getDefaultState()),
  [SETUP_DONATIONS_STAT_STATE]: (state) =>
    Object.assign(state, getDefaultState()),
  [SETUP_AVERAGE_GIFT_STATE]: (state) =>
    Object.assign(state, getDefaultState()),
  [SET_ACTIVITIES]: (state, activities) => (state.activities = activities),
  [SET_DONATIONS_STAT]: (state, donations) => (state.donations = donations),
  [SET_AVERAGE_GIFT]: (state, averageGift) => (state.averageGift = averageGift),
  [SET_ENQUIRY_TOTALS]: (state, enquiryTotals) =>
    (state.enquiryTotals = enquiryTotals),
  [SET_BOOKING_TOTALS]: (state, bookingTotals) =>
    (state.bookingTotals = bookingTotals),
  [SET_TOTALS]: (state, firmTotals) => (state.firmTotals = firmTotals),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
