import {
  SETUP_DONATION,
  SET_DONATION,
  SET_DONATIONS,
} from "@store/mutation-types";

import { http } from "@plugins/axios";
import { supabase } from "@plugins/supabase";

const getDefaultState = () => {
  return {
    donation: {},
    donations: [],
  };
};

// initial state
const state = getDefaultState();

const getters = {
  donation: (state) => state.donation,
  donations: (state) => state.donations,
  payment: (state) => state.donation.payment,
  status: (state) => state.donation.status,
};

const actions = {
  resetDonationState({ commit }) {
    commit("SETUP_DONATION");
  },
  async setDonation({ commit }, donation) {
    commit("SET_DONATION", donation);
  },
  async fetchDonationByStripeId({ commit }, id) {
    const response = await http.get(`donations/?donationStripeId=${id}`);
    commit("SET_DONATION", response.data["results"][0]);
  },
  async addDonation({ commit }, donation) {
    if (!donation.donationClient) {
      donation.donationBilling.clientType = "Offline";
      await http
        .post("clients/", donation.donationBilling)
        .then(async (response) => {
          donation.donationClient = response.data.clientId;
        })
        .catch(function (error) {
          console.log(error.reponse.data);
        });
      let enquiry = {};
      enquiry.enquiryClient = donation.donationClient;
      enquiry.enquiryStatus = "Confirmed";
      enquiry.enquiryFirm = donation.donationFirm;
      enquiry.enquiryBranch = donation.donationBranch;

      await http
        .post("enquiries/", enquiry)
        .then(async (response) => {
          donation.donationEnquiry = response.data.enquiryId;
        })
        .catch(function (error) {
          console.log(error.reponse.data);
        });
    }
    await http
      .post("donations/", donation)
      .then(async (response) => {
        commit("SET_DONATION", response.data);
      })
      .catch(function (error) {
        console.log(error.reponse.data);
      });
  },
  async updateDonation({ commit }, donation) {
    await http
      .patch(`donations/${donation.donationId}`, donation)
      .then(async (response) => {
        commit("SET_DONATION", response.data);
      })
      .catch(function (error) {
        console.log(error.reponse.data);
      });
  },
  async fetchDonations({ commit }, firm) {
    try {
      const { data, error } = await supabase
        .from("api_donation")
        .select(
          `
          *,
          donationBranch:donationBranch_id (branchPostcode)`
        )
        .eq("donationFirm_id", firm)
        .eq("donationStatus", "Success")
        .order("donationCreateDate", { ascending: false });

      if (error) {
        throw error;
      }
      // console.log("Donations fetched:", data)
      commit("SET_DONATIONS", data);
    } catch (error) {
      console.error("Error fetching donations:", error);
    }
  },
};

const mutations = {
  [SETUP_DONATION]: (state) => Object.assign(state, getDefaultState()),
  [SET_DONATION]: (state, donation) => (state.donation = donation),
  [SET_DONATIONS]: (state, donations) => (state.donations = donations),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
